// scanditSdk.js
import { 
  BarcodeCapture,
  barcodeCaptureLoader,
  BarcodeCaptureOverlay,
  BarcodeCaptureSettings,
  Symbology 
} from 'scandit-web-datacapture-barcode';

import {
configure,
DataCaptureContext,
DataCaptureView,
Camera,
CameraSwitchControl,
LaserlineViewfinder,
FrameSourceState,
} from 'scandit-web-datacapture-core';

export default function createSDKFacade() {
let context;
let view;
let laserLineViewFinder;
let settings;
let barcodeCapture;
let overlay;
let host;
let cameraSwitchControl;
let camera;
let barcodeCaptureListener = null;

function createHostElementIfNeeded() {
  if (host) return host;
  host = document.createElement('div');
  host.style.width = '100%';
  host.style.height = '100%';
  return host;
}

return {
  async initialize(duplicateFilter = 0) {
    await configure({
      libraryLocation: new URL("/assets/scandit/library/engine", document.baseURI).toString(),
      licenseKey: process.env.REACT_APP_SCANDIT_LICENSE_KEY,
      moduleLoaders: [barcodeCaptureLoader()]
    });
    
    context = await DataCaptureContext.create();
    settings = new BarcodeCaptureSettings();
    
    settings.enableSymbologies([
      Symbology.EAN13UPCA,
      Symbology.EAN8,
      Symbology.UPCE,
      Symbology.Code39,
      Symbology.Code128,
    ]);

    settings.codeDuplicateFilter = duplicateFilter;

    view = await DataCaptureView.forContext(context);
    view.connectToElement(createHostElementIfNeeded());

    cameraSwitchControl = new CameraSwitchControl();
    view.addControl(cameraSwitchControl);

    barcodeCapture = await BarcodeCapture.forContext(context, settings);
    await barcodeCapture.setEnabled(false);

    overlay = await BarcodeCaptureOverlay.withBarcodeCaptureForView(barcodeCapture, view);
    laserLineViewFinder = new LaserlineViewfinder();
    await overlay.setViewfinder(laserLineViewFinder);
    await view.addOverlay(overlay);

    camera = Camera.default;
    await camera.applySettings(BarcodeCapture.recommendedCameraSettings);
    await context.setFrameSource(camera);
  },

  async updateDuplicateFilter(value) {
    if (!settings) return;
    settings.codeDuplicateFilter = value;
    if (barcodeCapture) {
      await barcodeCapture.applySettings(settings);
    }
  },

  async updateSymbologies(symbologies) {
    if (!settings) return;
    
    settings.enabledSymbologies.forEach(sym => {
      settings.enableSymbology(sym, false);
    });

    symbologies.forEach(sym => {
      settings.enableSymbology(sym, true);
    });

    if (barcodeCapture) {
      await barcodeCapture.applySettings(settings);
    }
  },

  connectToElement(element) {
    if (!view || !element) return;
    host = element;
    view.connectToElement(element);
  },

  detachFromElement() {
    if (!view) return;
    view.detachFromElement();
  },

  async enableCamera(enabled) {
    if (!camera) return;
    if (enabled) {
      await camera.switchToDesiredState(FrameSourceState.On);
    } else {
      await camera.switchToDesiredState(FrameSourceState.Off);
    }
  },

  async enableScanning(enabled) {
    if (!barcodeCapture) return;
    await barcodeCapture.setEnabled(enabled);
  },

  addBarcodeCaptureListener(listener) {
    if (!barcodeCapture || barcodeCaptureListener) return;
    barcodeCaptureListener = listener;
    barcodeCapture.addListener(listener);
  },

  removeBarcodeCaptureListener(listener) {
    if (!barcodeCapture || !barcodeCaptureListener) return;
    barcodeCapture.removeListener(listener);
    barcodeCaptureListener = null;
  }
};
}
