import React from 'react';
import LoaderSpiner from "react-loader-spinner";
import styles from './styles.module.css';
import {
    primaryColor,
} from "../../constants";

const SingleAbsoluteLoader = ({ className }) => {
  return (
    <div className={`${styles.modal} ${className}`}>
      <div className="loader">
        <LoaderSpiner type="Puff" color={primaryColor} height={40} width={40} />
      </div>
    </div>
  );
}

export default SingleAbsoluteLoader;