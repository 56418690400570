export const GET_LOCALITY_STORES_INIT = "GET_LOCALITY_STORES_INIT";
export const GET_LOCALITY_STORES_COMPLETE = "GET_LOCALITY_STORES_COMPLETE";
export const GET_LOCALITY_STORES_ERROR = "GET_LOCALITY_STORES_ERROR";

export const SET_CURRENT_STORE = "SET_CURRENT_STORE";

export const GET_STORE_PRODUCTS_INIT = "GET_STORE_PRODUCTS_INIT";
export const GET_STORE_PRODUCTS_COMPLETE = "GET_STORE_PRODUCTS_COMPLETE";
export const GET_STORE_PRODUCTS_ERROR = "GET_STORE_PRODUCTS_ERROR";

export const UPDATE_CART = "UPDATE_CART";

export const UPDATE_CART_INIT = "UPDATE_CART_INIT";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const UPDATE_CART_ERROR = "UPDATE_CART_ERROR";

export const REMOVE_ORNDE_INIT = "REMOVE_ORNDE_INIT";
export const REMOVE_ORNDE_SUCCESS = "REMOVE_ORNDE_SUCCESS";

export const REMOVE_ORDEN_CART_INIT = "REMOVE_ORDEN_CART_INIT";
export const REMOVE_ORDEN_CART_SUCCESS = "REMOVE_ORDEN_CART_SUCCESS";
export const REMOVE_ORDEN_CART_ERROR = "REMOVE_ORDEN_CART_ERROR";

export const GET_CART_INIT = "GET_CART_INIT";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_ERROR = "GET_CART_ERROR";

export const GET_STORE_DETAILS_INIT = "GET_STORE_DETAILS_INIT";
export const GET_STORE_DETAILS_COMPLETE = "GET_STORE_DETAILS_COMPLETE";
export const GET_STORE_DETAILS_ERROR = "GET_STORE_DETAILS_ERROR";

export const GET_PRODUCT_DETAIL_INIT = "GET_PRODUCT_DETAIL_INIT";
export const GET_PRODUCT_DETAIL_COMPLETE = "GET_PRODUCT_DETAIL_COMPLETE";
export const GET_PRODUCT_DETAIL_ERROR = "GET_PRODUCT_DETAIL_ERROR";

export const GET_CATEGORY_PRODUCTS_INIT = "GET_CATEGORY_PRODUCTS_INIT";
export const GET_CATEGORY_PRODUCTS_COMPLETE = "GET_CATEGORY_PRODUCTS_COMPLETE";
export const GET_CATEGORY_PRODUCTS_ERROR = "GET_CATEGORY_PRODUCTS_ERROR";

export const POST_REQUEST_CHECKOUT_INIT = "POST_REQUEST_CHECKOUT_INIT";
export const POST_REQUEST_CHECKOUT_COMPLETE = "POST_REQUEST_CHECKOUT_COMPLETE";
export const POST_REQUEST_CHECKOUT_ERROR = "POST_REQUEST_CHECKOUT_ERROR";

export const POST_PAYMENT_INIT = "POST_PAYMENT_INIT";
export const POST_PAYMENT_COMPLETE = "POST_PAYMENT_COMPLETE";
export const POST_PAYMENT_ERROR = "POST_PAYMENT_ERROR";

export const GET_ORNDEN_LIST_INIT = "GET_ORNDEN_LIST_INIT";
export const GET_ORNDEN_LIST_COMPLETE = "GET_ORNDEN_LIST_COMPLETE";
export const GET_ORNDEN_LIST_ERROR = "GET_ORNDEN_LIST_ERROR";

export const SELECT_CATEGORY = "SELECT_CATEGORY";

export const GET_DETAIL_ORDEN = "GET_DETAIL_ORDEN";

export const GET_DETAIL_ORDEN_INIT = "GET_DETAIL_ORDEN_INIT";
export const GET_DETAIL_ORDEN_ERROR = "GET_DETAIL_ORDEN_ERROR";

export const GET_TRACKING_ORDEN_INIT = "GET_TRACKING_ORDEN_INIT";
export const GET_TRACKING_ORDEN_COMPLETE = "GET_TRACKING_ORDEN_COMPLETE";
export const GET_TRACKING_ORDEN_ERROR = "GET_TRACKING_ORDEN_ERROR";

export const GET_ORNDENS_DELIVERY_INIT = "GET_ORNDENS_DELIVERY_INIT";
export const GET_ORNDENS_DELIVERY_COMPLETE = "GET_ORNDENS_DELIVERY_COMPLETE";
export const RESET_ORNDENS_DELIVERY_PRICES = "RESET_ORNDENS_DELIVERY_PRICES";

export const GET_ORNDENS_DELIVERY_ERROR = "GET_ORNDENS_DELIVERY_ERROR";

export const SET_DISTANCES_AND_IDS_INIT = "SET_DISTANCES_AND_IDS_INIT";