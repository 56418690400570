import * as types from "./types";

export function getLocalityStoresInit(search, page, idBrand) {
  return {
    type: types.GET_LOCALITY_STORES_INIT,
    search,
    page,
    idBrand,
  };
}

export function selectCategory(category) {
  return {
    type: types.SELECT_CATEGORY,
    payload: category,
  };
}

export function setCurrentStore(payload) {
  return {
    type: types.SET_CURRENT_STORE,
    payload,
  };
}

export function getStoreProductsInit(storeId, itemsPerPage, page) {
  return {
    type: types.GET_STORE_PRODUCTS_INIT,
    payload: storeId,
    itemsPerPage,
    page,
  };
}

export function getCategoryProductsInit(
  subcategory,
  family,
  page,
  itemsPerPage
) {
  return {
    type: types.GET_CATEGORY_PRODUCTS_INIT,
    subcategory,
    family,
    page,
    itemsPerPage,
  };
}

export function getStoreDetailsInit(storeId) {
  return {
    type: types.GET_STORE_DETAILS_INIT,
    payload: storeId,
  };
}

export function updateCart(payload) {
  return {
    type: types.UPDATE_CART,
    payload,
  };
}

export function updateCartInit(payload, callback) {
  return {
    type: types.UPDATE_CART_INIT,
    payload,
    callback,
  };
}

export function removeCartInit(payload) {
  return {
    type: types.REMOVE_ORDEN_CART_INIT,
    payload,
  };
}

export function removeOrnde(id) {
  return {
    type: types.REMOVE_ORNDE_SUCCESS,
    payload: id,
  };
}

export function getProductDetailInit(detailId, callback) {
  return {
    type: types.GET_PRODUCT_DETAIL_INIT,
    payload: detailId,
    callback,
  };
}

export function getProductDetailComplete(detail) {
  return {
    type: types.GET_PRODUCT_DETAIL_COMPLETE,
    payload: detail,
  };
}

export function postRequestCheckoutInit(data, idOrden) {
  return {
    type: types.POST_REQUEST_CHECKOUT_INIT,
    payload: idOrden,
    data,
  };
}

export function postPaymentInit(idOrden) {
  return {
    type: types.POST_PAYMENT_INIT,
    payload: idOrden,
  };
}

export function removeOrden() {
  return {
    type: types.REMOVE_ORNDE_INIT,
  };
}

export function getOrdenList(page) {
  return {
    type: types.GET_ORNDEN_LIST_INIT,
    payload: page,
  };
}

export function getCartInit() {
  return {
    type: types.GET_CART_INIT,
  };
}

export function getDetailOrden(detail) {
  return {
    type: types.GET_DETAIL_ORDEN,
    detail,
  };
}

export function getDetailOrdenCall(id) {
  return {
    type: types.GET_DETAIL_ORDEN_INIT,
    payload: id,
  };
}

export function getDetailOrdenTrackingCall(id) {
  return {
    type: types.GET_TRACKING_ORDEN_INIT,
    payload: id,
  };
}

export function getOrdensDeliveryCall(payload,callback) {
  return {
    type: types.GET_ORNDENS_DELIVERY_INIT,
    payload,
    callback,
  };
}

export function resetOrdensDeliveryPrices() {
  return {
    type: types.RESET_ORNDENS_DELIVERY_PRICES
  };
}

export function setDistancesAndIds(payload) {
  return {
    type: types.SET_DISTANCES_AND_IDS_INIT,
    payload,
  };
}
