import { REHYDRATE } from "redux-persist/es/constants";
import * as types from "./types";

const defaultInfoModal = {title: '', info: '', onAccept: () => null, onClose: () => null, isOpen: false}

const initialState = {
  localities: [],
  infoModal: {...defaultInfoModal},
  unregisterFlag: true,
};

export default function generalStore(state = initialState, action) {
  let incoming;
  switch (action.type) {
    case types.SET_UNREGISTER_FLAG:
      return {
        ...state,
        unregisterFlag: true,
      };
    case types.SET_SELECTED_LOCALITY:
      return {
        ...state,
        selectedLocality: action.payload,
      };
    case types.GET_LOCALITIES_COMPLETE:
      return {
        ...state,
        localities: action.payload,
      };
    case types.GET_CATEGORIES_COMPLETE:
      return {
        ...state,
        categories: action.payload,
      };
    case types.OPEN_INFO_MODAL:
      return {
        ...state,
        infoModal: {...defaultInfoModal, ...action.payload, isOpen: true},
      };
    case types.OPEN_CART_MODAL:
      return {
        ...state,
        isOpenCartModal: action.payload,
      };
    case types.CLOSE_INFO_MODAL:
      return {
        ...state,
        infoModal: {...state.infoModal, isOpen: false},
      };
    case types.SHOW_SNACKBAR:
      return {
        ...state,
        snackbar: {
          open: true,
          message: action.payload,
          action: action.action,
          onClickAction: action.onClickAction,
        },
      };
    case types.CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: { ...state.snackbar, open: false },
      };
    case types.GET_STORE_LIST_INIT:
      return {
        ...state,
        isLoadingStoreList: true,
      };
    case types.GET_STORE_LIST_COMPLETE:
      return {
        ...state,
        isLoadingStoreList: false,
        storeList: action.payload,
      };
    case types.GET_STORE_LIST_ERROR:
      return {
        ...state,
        isLoadingStoreList: false,
      };
      case types.SET_ID_BRAND_LOGIN:
        return {
          ...state,
          idBrandLogin: action.idBrand,
        };

    case REHYDRATE:
      incoming = action.payload ? action.payload.generalStore : null;
      if (incoming) {
        return {
          ...incoming,
          isOpenCartModal: false,
          isLoadingStoreList: false,
          infoModal: defaultInfoModal,
        };
      }
      return state;
    default:
      return state;
  }
}
