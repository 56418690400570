/* eslint-disable no-return-assign */
import { put, call, takeLatest, select } from 'redux-saga/effects';
import queryString from 'querystring-es3';
import * as types from './types';
// eslint-disable-next-line import/no-cycle
import API from '../../api';
import { selectedLocalitySelector, idBrandLoginSelector } from '../general/selectors';
import { postPaymentInit, getDetailOrden } from './actions';
import { openInfoModal, setIdBrandLogin } from '../general/actions';
import { userSelector } from '../access/selectors';

export function* getLocalityStoresCall({ search, page, idBrand }) {
  try {
    const selectedLocality = yield select(selectedLocalitySelector);
    const idBrandLoginCall = yield select(idBrandLoginSelector);
    const result = yield call(
      API.get,
      `/commerces?page=${page !== undefined ? page : 1}${idBrand ? `&brand=${idBrand}` : ""}&itemsPerPage=20${
        selectedLocality !== undefined
          ? `&commune=${selectedLocality.value}${search || ''}`
          : `${search || ''}`
      }`,
      {
        headers: { noAuth: true },
      }
    );
    if (result.status === 200 && Array.isArray(result.data.commerces)) {
      yield put({
        type: types.GET_LOCALITY_STORES_COMPLETE,
        payload: result.data.commerces,
        quantity: result.data.quantity,
      });
      if (typeof idBrandLoginCall === "string" && idBrandLoginCall !== null && idBrandLoginCall !== undefined) {
        yield put( setIdBrandLogin(null) );
    }
    } else {
      yield put({ type: types.GET_LOCALITY_STORES_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_LOCALITY_STORES_ERROR });
  }
}

export function* getStoreProductsCall({ payload, page, itemsPerPage }) {
  try {
    const isAsc = payload.indexOf('ASC') !== -1;
    const isDesc = payload.indexOf('DESC') !== -1;
    const params = queryString.decode(payload);

    const maxPrice = params.maxPrice ? parseInt(params.maxPrice, 10) : 1000000000;
    const minPrice = params.minPrice ? parseInt(params.minPrice, 10) : 0;

    const result = yield call(
      API.get,
      `/products?itemsPerPage=${itemsPerPage || 28}&page=${page || 1}&commerce=${payload}`,
      {
        headers: { noAuth: true },
      }
    );
    if (result.status === 200 && Array.isArray(result.data.products)) {
      const data = result.data.products.map((item) => ({
        ...item,
        variants: item.variants.map((v) => ({
          ...v,
          variants: Array.isArray(v.variants)
            ? v.variants
                .map((c) => ({
                  ...c,
                  stocks: Array.isArray(c.stocks)
                    ? c.stocks
                    : Object.keys(c.stocks).map((d) => c.stocks[d]),
                }))
                .sort((a, b) => {
                  const aValue = parseInt(a.price, 10);
                  const bValue = parseInt(b.price, 10);
                  if (aValue < minPrice || aValue > maxPrice) {
                    return 1;
                  }
                  if (bValue < minPrice || bValue > maxPrice) {
                    return -1;
                  }
                  if (bValue < aValue && isAsc) return 1;
                  if (bValue > aValue && isAsc) return -1;
                  if (bValue < aValue && isDesc) return -1;
                  if (bValue > aValue && isDesc) return 1;
                  return 0;
                })
            : Object.keys(v.variants)
                .map((c) => ({
                  ...v.variants[c],
                  stocks: Array.isArray(v.variants[c].stocks)
                    ? v.variants[c].stocks
                    : Object.keys(v.variants[c].stocks).map((d) => v.variants[c].stocks[d]),
                }))
                .sort((a, b) => {
                  const aValue = parseInt(a.price, 10);
                  const bValue = parseInt(b.price, 10);
                  if (aValue < minPrice || aValue > maxPrice) {
                    return 1;
                  }
                  if (bValue < minPrice || bValue > maxPrice) {
                    return -1;
                  }
                  if (bValue < aValue && isAsc) return 1;
                  if (bValue > aValue && isAsc) return -1;
                  if (bValue < aValue && isDesc) return -1;
                  if (bValue > aValue && isDesc) return 1;
                  return 0;
                }),
        })),
      }));
      yield put({
        type: types.GET_STORE_PRODUCTS_COMPLETE,
        payload: {
          data,
          totalPages: Math.ceil(result.data.quantity / itemsPerPage),
        },
      });
    } else {
      yield put({ type: types.GET_STORE_PRODUCTS_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_STORE_PRODUCTS_ERROR });
  }
}

export function* getStoreDetailsCall({ payload }) {
  try {
    const result = yield call(API.get, `/api/commerces/${payload}?groups[]=commerces`, {
      headers: { noAuth: true },
    });
    if (result.status === 200 && result.data['@type'] === 'Commerce') {
      yield put({
        type: types.GET_STORE_DETAILS_COMPLETE,
        payload: result.data,
      });
    } else {
      yield put({ type: types.GET_STORE_DETAILS_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_STORE_DETAILS_ERROR });
  }
}

export function* getDetailProduct({ payload, callback }) {
  try {
    const result = yield call(API.get, `/api/products/${payload}`, {
      headers: { noAuth: true },
    });

    if (result.status === 200 && Object.values(result.data).length > 0) {
      yield put({
        type: types.GET_PRODUCT_DETAIL_COMPLETE,
        payload: result.data,
      });
    } else {
      yield put({ type: types.GET_PRODUCT_DETAIL_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_PRODUCT_DETAIL_ERROR });
    callback({ error: error.response });
  }
}

export function* getCategoryProductsCall({ subcategory, family, itemsPerPage, page }) {
  try {
    const locality = yield select(selectedLocalitySelector);
    const result = yield call(
      API.get,
      `/products?itemsPerPage=${itemsPerPage || 20}&page=${page || 1}${
        locality && locality.value ? `&commune=${locality.value}` : ''
      }${subcategory ? `&categories=[${subcategory}]` : ''}${
        family ? `&subCategories=[${family}]` : ''
      }`,
      {
        headers: { noAuth: true },
      }
    );
    if (result.status === 200 && Array.isArray(result.data.products)) {
      const payload = result.data.products.map((item) => ({
        ...item,
        variants: item.variants.map((v) => ({
          ...v,
          variants: Array.isArray(v.variants)
            ? v.variants.map((c) => ({
                ...c,
                stocks: Array.isArray(c.stocks)
                  ? c.stocks
                  : Object.keys(c.stocks).map((d) => c.stocks[d]),
              }))
            : Object.keys(v.variants).map((c) => ({
                ...v.variants[c],
                stocks: Array.isArray(v.variants[c].stocks)
                  ? v.variants[c].stocks
                  : Object.keys(v.variants[c].stocks).map((d) => v.variants[c].stocks[d]),
              })),
        })),
      }));
      yield put({
        type: types.GET_CATEGORY_PRODUCTS_COMPLETE,
        payload: {
          data: payload,
          totalPages: Math.ceil(result.data.quantity / itemsPerPage),
        },
      });
    } else {
      yield put({ type: types.GET_CATEGORY_PRODUCTS_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_CATEGORY_PRODUCTS_ERROR });
  }
}

export function* updateCartCall({ payload, callback }) {
  const user = yield select(userSelector);
  try {
    if (user && user.id) {
      const result = yield call(API.post, `/api/users/${user.id}/carts/products`, payload);
      if (result.status >= 200 && Array.isArray(result.data.cart)) {
        const data = result.data.cart.map((order) => ({
          ...order,
          ordenItems: order.ordenItems.map((orderItem) => ({
            ...orderItem,
            product: {
              ...orderItem.product,
              stocks: [orderItem.stock],
            },
          })),
        }));
        yield put({
          type: types.UPDATE_CART_SUCCESS,
          payload: data,
        });
        if (typeof callback === 'function') callback({ success: true, data: result.data });
      } else {
        yield put({ type: types.UPDATE_CART_ERROR });
      }
    }
  } catch (error) {
    yield put({ type: types.UPDATE_CART_ERROR });
  }
}

export function* removeItemCartCall({ payload }) {
  try {
    const result = yield call(API.delete, `/api/orden_items/${payload}`);
    if (result) yield put({ type: types.REMOVE_ORDEN_CART_SUCCESS });
  } catch (error) {
    yield put({ type: types.REMOVE_ORDEN_CART_ERROR });
  }
}

export function* requestCheckoutCall({ data }) {
  try {
    const result = yield call(API.post, `/api/ordens/request`, data);
    if (result) yield put(postPaymentInit(result.data));
    yield put({ type: types.POST_REQUEST_CHECKOUT_COMPLETE, payload: data });
  } catch (error) {
    yield put({ type: types.POST_REQUEST_CHECKOUT_ERROR });
  }
}

export function* paymentCall({ payload }) {
  try {
    const result = yield call(
      API.get,
      `/api/ordens/payment-methods?${payload.map(({ id }) => `ordens[]=${id}`).join('&')}`
    );
    if (result.data && result.data.link) {
      const a = document.createElement('a');
      a.href = result.data.link;
      a.click();
      // const urlApi = result.data.url;
      // const tokenWs = result.data.token_ws;
      // if (urlApi) fakePost(urlApi, { token_ws: tokenWs });
    }
  } catch (error) {
    if (error?.response?.data?.type === "stockout_order") {
      yield put({ type: types.GET_CART_SUCCESS, payload: error.response.data.cart });
      yield put(
        openInfoModal({
          title: 'Disculpe',
          info: 'Su orden fue ajustada por falta de inventario, verifique e intente pagar nuevamente',
        })
      );
      return
    }
    if (error?.response?.data?.type === "empty_order") {
      yield put(
        openInfoModal({
          title: 'Informacion',
          info: 'Debe añadir productos al carrito para poder pagar',
        })
      );
      return
    }
    if (error?.response?.data?.type === "payment_platform") {
      yield put(
        openInfoModal({
          title: 'Disculpe',
          info: 'Ha ocurrido un error con la plataforma de pago',
        })
      );
      return
    }
    yield put(
      openInfoModal({
        title: 'Disculpe',
        info: 'Ha ocurrido un error interno',
      })
    );
    yield put({ type: types.UPDATE_CART_ERROR });
  }
}

export function* getCartCall() {
  try {
    const result = yield call(API.get, `/api/cart`);
    if (Array.isArray(result.data) && result.data.length >= 0) { // && result.data.length // it was an problem when cart === 0
      yield put({ type: types.GET_CART_SUCCESS, payload: result.data });
    }
  } catch (error) {
    yield put({ type: types.GET_CART_ERROR });
  }
}

export function* getOrdenList({ payload }) {
  const userId = yield select((state) => state.accessStore.user.id);
  try {
    const result = yield call( 
      API.get,
      `/api/ordens?order[date]=DESC&groups[]=orden-user&user=${userId}&pagination=true&page=${payload}&itemsPerPage=10`
    );
    if (result.status === 200 && Array.isArray(result.data[`hydra:member`])) {
      yield put({
        type: types.GET_ORNDEN_LIST_COMPLETE,
        payload: result.data,
      });
    } else {
      yield put({ type: types.GET_ORNDEN_LIST_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_ORNDEN_LIST_ERROR });
  }
}

export function* getDetailOrdenCall({ payload }) {
  try {
    const result = yield call(API.get, `/api/ordens/${payload}`);
    if (result.data) {
      yield put(getDetailOrden(result.data));
    }
  } catch (error) {
    yield put({ type: types.GET_DETAIL_ORDEN_ERROR });
  }
}

export function* getDetailOrdenTrackingCall({ payload }) {
  try {
    const result = yield call(API.get, `/api/ordens/${payload}/tracking`);
    if (result.status >= 200 && result.status <= 299) {
      yield put({
        type: types.GET_TRACKING_ORDEN_COMPLETE,
        payload: result.data,
      });
    } else {
      yield put({ type: types.GET_TRACKING_ORDEN_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_TRACKING_ORDEN_ERROR });
  }
}

export function* ordensDeliveryCall({ payload, callback }) {
  try {
    const result = yield call(API.get, `/api/ordens/delivery_prices?commune=${payload}`);
    if (result.status >= 200 && result.status <= 299) {
      if (result && Array.isArray(result.data) && result.data.length) {
        yield put({
          type: types.GET_ORNDENS_DELIVERY_COMPLETE,
          payload: result.data,
        });
        callback({bool:true}); // // // // callback
      }else{
        yield put({ type: types.GET_ORNDENS_DELIVERY_ERROR });
        callback({bool:false}); // // // // callback
      }
    }
  } catch (error) {
    yield put({ type: types.GET_ORNDENS_DELIVERY_ERROR });
    callback({bool:false}); // // // // callback
  }
}

export function* resetDeliveryPrices() {
  yield put({
    type: types.GET_ORNDENS_DELIVERY_COMPLETE,
    payload: [],
  });
}



export default function* shopping() {
  yield takeLatest(types.GET_LOCALITY_STORES_INIT, getLocalityStoresCall);
  yield takeLatest(types.GET_STORE_PRODUCTS_INIT, getStoreProductsCall);
  yield takeLatest(types.GET_STORE_DETAILS_INIT, getStoreDetailsCall);
  yield takeLatest(types.GET_PRODUCT_DETAIL_INIT, getDetailProduct);
  yield takeLatest(types.GET_CATEGORY_PRODUCTS_INIT, getCategoryProductsCall);
  yield takeLatest(types.UPDATE_CART_INIT, updateCartCall);
  yield takeLatest(types.REMOVE_ORDEN_CART_INIT, removeItemCartCall);
  yield takeLatest(types.GET_CART_INIT, getCartCall);
  yield takeLatest(types.POST_REQUEST_CHECKOUT_INIT, requestCheckoutCall);
  yield takeLatest(types.POST_PAYMENT_INIT, paymentCall);
  yield takeLatest(types.GET_ORNDEN_LIST_INIT, getOrdenList);
  yield takeLatest(types.GET_DETAIL_ORDEN_INIT, getDetailOrdenCall);
  yield takeLatest(types.GET_ORNDENS_DELIVERY_INIT, ordensDeliveryCall);
  yield takeLatest(types.RESET_ORNDENS_DELIVERY_PRICES, resetDeliveryPrices);
  yield takeLatest(types.GET_TRACKING_ORDEN_INIT, getDetailOrdenTrackingCall);
}
