/* eslint-disable import/no-cycle */
import { all } from "redux-saga/effects";
import access from "./access/sagas";
import general from "./general/sagas";
import shopping from "./shopping/sagas";
import store from "./store/sagas";

export default function* rootSaga() {
  yield all([access(), general(), shopping(), store()]);
}
