import { createWithEqualityFn } from 'zustand/traditional'
import { getLocalStorageShopCartItems, getUserCommerce, getUserRole, parseShopCartItems } from '../utils'
import { getShopCart } from '../services/api'
import { ROLE_COMMERCE, ROLE_PROMOTER } from '../constants'

const getCommerceShopCartItems = ({ commerce }) => {
    let items
    try {
        items = getLocalStorageShopCartItems(commerce.id) || []
    } catch (error) {
        items = []
    }
    return items
}

const getPromoterShopCartItems = async () => {
    let items
    try {
        const { data: { items : storeCartItems } } = await getShopCart()
        items = parseShopCartItems(storeCartItems)
    } catch (error) {
        items = []
    }
    return items
}

const getBootItems = async ({ user }) => {
    const userRole = getUserRole(user)
    const userCommerce = getUserCommerce(user)
    
    let items = []
    
    if (userRole === ROLE_COMMERCE) {
        items = getCommerceShopCartItems({ commerce: userCommerce})
    }

    if (userRole === ROLE_PROMOTER) {
        items = await getPromoterShopCartItems()
    }

    return items
}

const useShopCartStore = createWithEqualityFn((set, get) => ({
    shopCart: {
        user: null,
        items: [],
        isLoaded: false,
        isOpenModal: false,
        isOpenPromotorModal: false,
        version: 0
    },
    loadBootItems: async ({ user }) => {
        const userRole = getUserRole(user)
        const { shopCart }  = get()
        
        if (shopCart.isLoaded || ![ROLE_PROMOTER, ROLE_COMMERCE].includes(userRole)) {
            set({
                ...shopCart
            })
        }

        const items = await getBootItems({ user })
        set({
            shopCart: {
                ...shopCart,
                user,
                items,
                isLoaded: true,
                version: shopCart.version + 1
            }
        })
    },
    updateShopCartItem: (item, quantity) => set(
        (state) => {
            const { shopCart } = state
            const shopCartItemMatched = shopCart
                .items
                .find(shopCartItem => (
                    shopCartItem.variant2Id === item.variant2Id
                ))
            let items = []
            if (shopCartItemMatched) {
                items = shopCart
                    .items
                    .map(shopCartItem => {
                        if (shopCartItem.variant2Id === item.variant2Id) {
                            return {
                                ...shopCartItem,
                                quantity,
                            }
                        }
                        return { ...shopCartItem }
                    })
            }
            if (!shopCartItemMatched) {
                items = [
                    {
                        ...item,
                        quantity
                    },
                    ...shopCart.items
                ]
            }
            
            const shopCartUpdated = { ...shopCart, items, version: shopCart.version + 1 }

            // syncShopCartItemStorage(shopCartUpdated)

            return { shopCart: shopCartUpdated }
        }
    ),

    removeShopCartItem: (item) => set(
        (state) => {
            const { shopCart } = state
            const shopCartItemMatched = shopCart
                .items
                .find(shopCartItem => (
                    shopCartItem.variant2Id === item.variant2Id
                )) 
            if (!shopCartItemMatched) {
                return state
            }
            const items = [
                ...shopCart
                    .items
                    .filter(i => i.variant2Id !== item.variant2Id)
            ]

            const shopCartUpdated = { ...shopCart, items, version: shopCart.version + 1 }

            // syncShopCartItemStorage(shopCartUpdated)
            
            return { shopCart: shopCartUpdated }
        }
    ),

    incrementShopCartItem: (item, quantity) => set(
        (state) => {
            const { shopCart } = state
            const shopCartItemMatched = shopCart
                .items
                .find(shopCartItem => shopCartItem.variant2Id === item.variant2Id) 
            
            let items = []
            
            if (shopCartItemMatched) {
                items = shopCart
                    .items
                    .map(shopCartItem => {
                        if (shopCartItem.variant2Id === item.variant2Id) {
                            return {
                                ...shopCartItem,
                                quantity: shopCartItem.quantity + quantity < 1 ? 1 : shopCartItem.quantity + quantity,
                            }
                        }
                        return shopCartItem
                     })
            }

            if (!shopCartItemMatched) {
                items = [
                    { ...item, quantity: quantity < 1 ? 1 : quantity },
                    ...shopCart.items,
                ]
            }
            
            const shopCartUpdated = { ...shopCart, items, version: shopCart.version + 1 }

            // syncShopCartItemStorage(shopCartUpdated)
            
            return { shopCart: shopCartUpdated }
        }
    ),

    clearShopCart: () => set(
        ({ shopCart }) => {

            const shopCartUpdated = { ...shopCart, items: [] }

            // syncShopCartItemStorage(shopCartUpdated)

            return { shopCart: shopCartUpdated, version: shopCart.version + 1 }
    }),

    toggleShopCartModal: () => set(
        ({ shopCart }) => {
            return { 
                shopCart: { 
                    ...shopCart,
                    isOpenModal: !shopCart.isOpenModal 
            } 
        }}
    ),

    toggleShopCartPromotorModal: () => set(
        ({ shopCart }) => {
            return { 
                shopCart: { 
                    ...shopCart,
                    isOpenModalPromotor: !shopCart.isOpenModalPromotor 
                } 
            }
        }
    ),
    shopCartReset: () => set(
        ({ shopCart }) => ({
            shopCart: {
                user: null,
                items: [],
                isLoaded: false,
                isOpenModal: false,
                isOpenPromotorModal: false,
                version: shopCart.version + 1
            }
    })),
    setShopCartItems: ({ shopCartItems }) => set(
        ({ shopCart }) => (
            {
                shopCart: {
                    ...shopCart,
                    items: shopCartItems,
                    version: shopCart.version + 1
                }
            }
        )
    ),
    addShopCartItems: ({ shopCartItems }) => set(
        (state) => {
            const currentShopCartItemsNotIncluded = state.shopCart.items?.filter(({ sku }) => (
                !shopCartItems?.some(({ sku: shopCartItemSku }) => sku === shopCartItemSku )
            ))
            return {
                shopCart: {
                    ...state.shopCart,
                    items: [...currentShopCartItemsNotIncluded, ...shopCartItems],
                    version: state.shopCart.version + 1
                }
            }
        }
    ),
}))

export default useShopCartStore