import { create } from "zustand";

const useConfirmationMessageModal = create((set) => ({
    isOpenConfirmationMessageModal: false,
    confirmationMessageModal: null,
    onAcceptConfirmationMessageModal: null,
    onCancelConfirmationMessageModal: null,
    openConfirmationMessageModal: ({ message, onAccept, onCancel }) => set(
        () => ({
            isOpenConfirmationMessageModal: true,
            confirmationMessageModal: message,
            onAcceptConfirmationMessageModal: onAccept,
            onCancelConfirmationMessageModal: onCancel
        })
    ),
    closeConfirmationMessageModal: () => set(
        () => ({
            isOpenConfirmationMessageModal: false
        })
    )

}))

export default useConfirmationMessageModal