import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { primaryColor } from "../constants";

const Container = styled.div`
  width: 100%;
  position: relative;
  max-height: ${(props) => (props.visible ? "100vh" : "auto")};
  overflow: ${(props) =>
    props.visible && !props.overflow ? "hidden" : props.overflow};
`;

const AbsoluteLoader = ({
  opacity,
  id,
  className,
  visible,
  overflow,
  children,
  noLoader,
  success,
  message,
}) => {
  return (
    <Container
      id={id}
      className={className}
      visible={visible}
      overflow={overflow}
    >
      {children}
      <div
        style={{
          // opacity: visible? 0.9 : 0.0,
          backgroundColor: visible
            ? `rgba(255,255,255, ${opacity})`
            : "#000088",
          transitionDuration: "2s",
          transitionProperty: "opacity",
          display: visible ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          flexDirection: "column",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 9999999999999999999,
        }}
      >
        {visible && !noLoader && !success ? (
          <Loader type="Puff" color={primaryColor} height={40} width={40} />
        ) : null}

        {
          <p style={{ marginTop: 10 }} className="small-text white-text">
            {message}
          </p>
        }
      </div>
    </Container>
  );
};

AbsoluteLoader.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  opacity: PropTypes.number,
  noLoader: PropTypes.bool,
  success: PropTypes.bool,
  message: PropTypes.string,
  overflow: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
};

AbsoluteLoader.defaultProps = {
  visible: false,
  children: undefined,
  opacity: 0.9,
  noLoader: false,
  success: false,
  message: undefined,
  overflow: undefined,
  className: undefined,
  id: undefined,
};

export default AbsoluteLoader;
