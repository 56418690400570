export const GET_STORE_ORDERS_INIT = "GET_STORE_ORDERS_INIT";
export const GET_STORE_ORDERS_COMPLETE = "GET_STORE_ORDERS_COMPLETE";
export const GET_STORE_ORDERS_ERROR = "GET_STORE_ORDERS_ERROR";

export const GET_STORE_SUBCATEGORY_INIT = "GET_STORE_SUBCATEGORY_INIT";
export const GET_STORE_SUBCATEGORY_COMPLETE = "GET_STORE_SUBCATEGORY_COMPLETE";
export const GET_STORE_SUBCATEGORY_ERROR = "GET_STORE_SUBCATEGORY_ERROR";

export const SET_CURRENT_STORE_ORDER = "SET_CURRENT_STORE_ORDER";

export const UPDATE_STORE_MANAGER_INIT = "UPDATE_STORE_MANAGER_INIT";
export const UPDATE_STORE_MANAGER_COMPLETE = "UPDATE_STORE_MANAGER_COMPLETE";
export const UPDATE_STORE_MANAGER_ERROR = "UPDATE_STORE_MANAGER_ERROR";

export const SHOW_MANAGER_FORM = "SHOW_MANAGER_FORM";

export const UPDATE_STORE_ORDER_INIT = "UPDATE_STORE_ORDER_INIT";
export const UPDATE_STORE_ORDER_COMPLETE = "UPDATE_STORE_ORDER_COMPLETE";
export const UPDATE_STORE_ORDER_ERROR = "UPDATE_STORE_ORDER_ERROR";

export const REFUND_ORDER_INIT = "REFUND_ORDER_INIT";
export const REFUND_ORDER_COMPLETE = "REFUND_ORDER_COMPLETE";
export const REFUND_ORDER_ERROR = "REFUND_ORDER_ERROR";

export const GET_STORE_VARIANTS_INIT = "GET_STORE_VARIANTS_INIT";
export const GET_STORE_VARIANTS_COMPLETE = "GET_STORE_VARIANTS_COMPLETE";
export const GET_STORE_VARIANTS_ERROR = "GET_STORE_VARIANTS_ERROR";

export const GET_STORE_MANIFEST_INIT = "GET_STORE_MANIFEST_INIT";
export const GET_STORE_MANIFEST_COMPLETE = "GET_STORE_MANIFEST_COMPLETE";
export const GET_STORE_MANIFEST_ERROR = "GET_STORE_MANIFEST_ERROR";

export const UPDATE_STORE_INIT = "UPDATE_STORE_INIT";
export const UPDATE_STORE_COMPLETE = "UPDATE_STORE_COMPLETE";
export const UPDATE_STORE_ERROR = "UPDATE_STORE_ERROR";

export const UPDATE_STORE_MANIFEST_INIT = "UPDATE_STORE_MANIFEST_INIT";
export const UPDATE_STORE_MANIFEST_COMPLETE = "UPDATE_STORE_MANIFEST_COMPLETE";

export const POST_STORE_MANIFEST_INIT = "POST_STORE_MANIFEST_INIT";
export const POST_STORE_MANIFEST_COMPLETE = "POST_STORE_MANIFEST_COMPLETE";
export const POST_STORE_MANIFEST_ERROR = "POST_STORE_MANIFEST_ERROR";

export const GET_STORE_MANIFEST_ORDERS_INIT = "GET_STORE_MANIFEST_ORDERS_INIT";
export const GET_STORE_MANIFEST_ORDERS_COMPLETE =
  "GET_STORE_MANIFEST_ORDERS_COMPLETE";
export const GET_STORE_MANIFEST_ORDERS_ERROR =
  "GET_STORE_MANIFEST_ORDERS_ERROR";

export const GET_DETAIL_COMMERCE = "GET_DETAIL_COMMERCE";
export const GET_DETAIL_COMMERCE_COMPLETE = "GET_DETAIL_COMMERCE_COMPLETE";
export const GET_DETAIL_COMMERCE_ERROR = "GET_DETAIL_COMMERCE_ERROR";

export const GET_MATRIX_PRODUCTS_INIT = "GET_MATRIX_PRODUCTS_INIT";
export const GET_MATRIX_PRODUCTS_COMPLETE = "GET_MATRIX_PRODUCTS_COMPLETE";
export const GET_MATRIX_PRODUCTS_ERROR = "GET_MATRIX_PRODUCTS_ERROR";





export const GET_STOCKS_BY_VARIANT_INIT = "GET_STOCKS_BY_VARIANT_INIT";
export const GET_STOCKS_BY_VARIANT_COMPLETE = "GET_STOCKS_BY_VARIANT_COMPLETE";
export const GET_STOCKS_BY_VARIANT_ERROR = "GET_STOCKS_BY_VARIANT_ERROR";

export const UPDATE_I_VARIANT_2_INIT = "UPDATE_I_VARIANT_2_INIT";

export const GET_TEMPORADAS_INIT = "GET_TEMPORADAS_INIT";
export const GET_TEMPORADAS_COMPLETE = "GET_TEMPORADAS_COMPLETE";
export const GET_TEMPORADAS_ERROR = "GET_TEMPORADAS_ERROR";

export const GET_FILTERS_GRAL_INIT = "GET_FILTERS_GRAL_INIT";
export const GET_FILTERS_GRAL_COMPLETE = "GET_FILTERS_GRAL_COMPLETE";
export const GET_FILTERS_GRAL_ERROR = "GET_FILTERS_GRAL_ERROR";

export const SET_FIELD_SORT = "SET_FIELD_SORT";