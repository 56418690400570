export const localityStoresSelector = (state) =>
  state.shoppingStore.localityStores;
export const isGettingLocalityStoresSelector = (state) =>
  state.shoppingStore.isGettingLocalityStores;
export const storeProductsSelector = (state) =>
  state.shoppingStore.storeProducts;
export const isGettingStoreProductsSelector = (state) =>
  state.shoppingStore.isGettingStoreProducts;
export const categoryProductsSelector = (state) =>
  state.shoppingStore.categoryProducts;
export const isGettingCategoryProductsSelector = (state) =>
  state.shoppingStore.isGettingCategoryProducts;
export const currentStoreSelector = (state) => state.shoppingStore.currentStore;
export const isGettingStoreDetailsSelector = (state) =>
  state.shoppingStore.isGettingStoreDetails;
export const cartSelector = (state) => state.shoppingStore.cart || [];
export const ordenDetailStoreSelector = (state) =>
  state.shoppingStore.detailOrden;
export const isRequestingCheckoutSelector = (state) =>
  state.shoppingStore.isRequestingCheckout;
export const isUpdatingCartSelector = (state) =>
  state.shoppingStore.isUpdatingCart;
export const isGettingCartSelector = (state) =>
  state.shoppingStore.isGettingCart;
export const isGettingOrdensDeliverySelector = (state) =>
  state.shoppingStore.ordensDelivery;
export const isLoadingOrdensDeliverySelector = (state) =>
  state.shoppingStore.isLoadingDelivery;
export const isLoadingTrackingSelector = (state) =>
  state.shoppingStore.isLoadingTracking;
export const trackingStatusSelector = (state) =>
  state.shoppingStore.trackingStatus;
export const distancesAndIdsSelector = (state) => state.shoppingStore.distancesAndIds;
