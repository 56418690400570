import * as types from "./types";

export default function getStoreOrdersInit(page, itemsPerPage, query) {
  return {
    type: types.GET_STORE_ORDERS_INIT,
    page,
    itemsPerPage,
    query,
  };
}

export function setCurrentStoreOrder(payload) {
  return {
    type: types.SET_CURRENT_STORE_ORDER,
    payload,
  };
}

export function updateStoreManagerInit(payload) {
  return {
    type: types.UPDATE_STORE_MANAGER_INIT,
    payload,
  };
}

export function updateStoreOrderInit(payload) {
  return {
    type: types.UPDATE_STORE_ORDER_INIT,
    payload,
  };
}

export function refundOrderInit(payload) {
  return {
    type: types.REFUND_ORDER_INIT,
    payload,
  };
}

export function updateStoreInit(payload, callback) {
  return {
    type: types.UPDATE_STORE_INIT,
    payload,
    callback,
  };
}

export function setShowManagerForm(payload) {
  return {
    type: types.SHOW_MANAGER_FORM,
    payload,
  };
}

export function getStoreSubCategory(payload) {
  return {
    type: types.GET_STORE_SUBCATEGORY_INIT,
    payload,
  };
}

export function getStoreVariantsInit(payload) {
  return {
    type: types.GET_STORE_VARIANTS_INIT,
    payload,
  };
}

export function getStoreManifestInit(payload) {
  return {
    type: types.GET_STORE_MANIFEST_INIT,
    payload,
  };
}

export function getStoreManifestOrdersInit() {
  return {
    type: types.GET_STORE_MANIFEST_ORDERS_INIT,
  };
}

export function postStoreManifestInit(payload, callback) {
  return {
    type: types.POST_STORE_MANIFEST_INIT,
    payload,
    callback,
  };
}

export function getDetailCommerce() {
  return {
    type: types.GET_DETAIL_COMMERCE,
  };
}

export function getMatrixProducts(page, itemsPerPage, query, id, idCommerce, fieldSort,callback) {
  return {
    type: types.GET_MATRIX_PRODUCTS_INIT,
    page,
    itemsPerPage,
    query,
    id,  
    idCommerce,
    fieldSort,
    callback
  };
}

export function updateIvariant2(iVariant1Gral,iVariant2Gral) {
  return {
    type: types.UPDATE_I_VARIANT_2_INIT,
    iVariant1Gral,
    iVariant2Gral,
  };
}









export function getStocksByVariant(variantId, commerceId) {
  return {
    type: types.GET_STOCKS_BY_VARIANT_INIT,
    variantId, 
    commerceId
  };
}

export function getTemporadas() {
  return {
    type: types.GET_TEMPORADAS_INIT,
  };
}

export function getFiltersGral() {
  return {
    type: types.GET_FILTERS_GRAL_INIT,
  };
}

export function setFieldSort(fieldSort) {
  return {
    type: types.SET_FIELD_SORT,
    fieldSort
  };
}
