import { getUserCommerce, getUserRole } from "../../utils";

export const isRegisteringSelector = (state) => state.accessStore.isRegistering;
export const isRecoveringSelector = (state) => state.accessStore.isRecovering;
export const isLoggingInSelector = (state) => state.accessStore.isLoggingIn;
export const isLoggingSocialSelector = (state) =>
  state.accessStore.isLoggingSocial;
export const isResetingSelector = (state) => state.accessStore.isReseting;
export const isConfirmingEmailSelector = (state) =>
  state.accessStore.isConfirmingEmail;
export const tokenSelector = (state) => state.accessStore.token;
export const userSelector = (state) =>
  state.accessStore.user ? state.accessStore.user : {};
export const userRoleSelector = (state) => getUserRole(state?.accessStore?.user || {})
export const userCommerceSelector = (state) => getUserCommerce(state?.accessStore?.user || {})
export const storeSelector = (state) =>
  state.accessStore.user ? state.accessStore.user.commerce : {};
export const isUpdatingProfileSelector = (state) =>
  state.accessStore.isUpdatingProfile;
export const isSendingSMSSelector = (state) => state.accessStore.isSendingSMS;
export const isVerifyingPhoneSelector = (state) =>
  state.accessStore.isVerifyingPhone;
export const lastSMSSentTimeSelector = (state) =>
  state.accessStore.lastSMSSentTime;
export const isChangingPasswordSelector = (state) =>
  state.accessStore.isChangingPassword;

export const fcmTokenSelector = (state) => state.accessStore.fcmToken;
