import { put, call, takeLatest, select } from "redux-saga/effects";
// import queryString from 'querystring';
import { storeSelector, userSelector } from "../access/selectors";
import * as types from "./types";
// eslint-disable-next-line import/no-cycle
import API from "../../api";
import { openInfoModal } from "../general/actions";
import { currentStoreSelector } from "../shopping/selectors";
import { getDetailOrdenTrackingCall } from "../shopping/actions";

export function* getStoreOrdersCall({ page, itemsPerPage, query }) {
  try {
    const currentStore = yield select(userSelector);
    const store = yield select(storeSelector);
    if (!store) return;
    const result = yield call(
      API.get,
      `/api/ordens?order[date]=DESC${
        store && store.id ? `&groups[]=orden-commerce&commerce=${store.id}` : ""
      }${page ? `&page=${page}` : ""}${
        itemsPerPage ? `&itemsPerPage=${itemsPerPage}` : ""
      }${query || ""}${
        currentStore && currentStore.brand && currentStore.brand.id
          ? `&commerce.brand=${currentStore.brand.id}`
          : ""
      }&groups[]=orden-commerce`
    );
    if (result.status === 200 && Array.isArray(result.data["hydra:member"])) {
      yield put({
        type: types.GET_STORE_ORDERS_COMPLETE,
        payload: {
          data: result.data["hydra:member"],
          totalPages: Math.ceil(result.data["hydra:totalItems"] / itemsPerPage),
        },
      });
    } else {
      yield put({ type: types.GET_STORE_ORDERS_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_STORE_ORDERS_ERROR });
  }
}

export function* updateStoreManagerCall({ payload }) {
  try {
    const store = yield select(storeSelector);
    if (!store) return;
    const result = yield call(
      payload.id ? API.put : API.post,
      `/api/dispatchers${payload.id ? `/${payload.id}` : ""}`,
      payload
    );
    if (result.status === 201 || result.status === 200) {
      yield put({
        type: types.UPDATE_STORE_MANAGER_COMPLETE,
        payload: result.data,
      });
    } else {
      yield put({ type: types.UPDATE_STORE_MANAGER_ERROR });
    }
  } catch (error) {
    yield put({ type: types.UPDATE_STORE_MANAGER_ERROR });
  }
}

export function* getStoreSubCategory({ payload }) {
  try {
    const result = yield call(
      API.get,
      `/api/categories?subCategories.subCategories.products.variants.variants.stocks.commerce=${payload}`,
      {
        headers: { noAuth: true },
      }
    );

    if (
      Array.isArray(result.data) &&
      result.data.length > 0
    ) {
      yield put({
        type: types.GET_STORE_SUBCATEGORY_COMPLETE,
        payload: result.data,
      });
    } else {
      yield put({ type: types.GET_STORE_SUBCATEGORY_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_STORE_SUBCATEGORY_ERROR });
  }
}

export function* getStoreVariantsCall({ payload }) {
  try {
    const currentStore = yield select(currentStoreSelector);
    const currentUser = yield select(userSelector);
    const query = payload
      ? `/variants?subCategory=${payload}${!currentUser.commerce ? `&commerce=${currentStore.id}` : ""}${currentUser.commerce && currentUser.commerce.wharehouse ? `&commerces=[${currentUser.commerce.wharehouse.id},${currentUser.commerce.id}]&allowEmptyStock=1` : ""}`
      : `/variants?commerce=${currentStore.id}`;
    const result = yield call(API.get, query, {
      headers: { noAuth: true },
    });
    if (Array.isArray(result.data.colors)) {
      yield put({
        type: types.GET_STORE_VARIANTS_COMPLETE,
        payload: result.data,
      });
    } else {
      yield put({ type: types.GET_STORE_VARIANTS_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_STORE_VARIANTS_ERROR });
  }
}

export function* updateStoreOrderCall({ payload }) {
  try {
    const result = yield call(API.put, `/api/ordens/${payload.id}`, payload);
    if (result.status === 201 || result.status === 200) {
      yield put({
        type: types.UPDATE_STORE_ORDER_COMPLETE,
        payload: result.data,
      });
      yield put(getDetailOrdenTrackingCall(payload.id));
    } else {
      yield put({ type: types.UPDATE_STORE_ORDER_ERROR });
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data["hydra:description"]
    ) {
      yield put(
        openInfoModal({
          title: "No se pudo actualizar la orden",
          info: error.response.data["hydra:description"],
        })
      );
    } else {
      yield put(
        openInfoModal({
          title: "No se pudo actualizar la orden",
          info: "Disculpe, ocurrio un error al intentar actualizar la orden.",
        })
      );
    }
    yield put({ type: types.UPDATE_STORE_ORDER_ERROR });
  }
}

export function* refundOrderCall({ payload }) {
  try {
    const result = yield call(
      API.post,
      `/api/ordens/${payload.order}/refund`,
      payload
    );
    if (result.status === 201 || result.status === 200) {
      yield put({
        type: types.REFUND_ORDER_COMPLETE,
        payload: result.data,
      });
    } else {
      yield put({ type: types.REFUND_ORDER_ERROR });
    }
  } catch (error) {
    yield put({ type: types.REFUND_ORDER_ERROR });
    yield put(
      openInfoModal({
        title: "No se pudo actualizar la orden",
        info: "Disculpe, ocurrio un error al intentar actualizar la orden.",
      })
    );
  }
}

export function* updateStoreCall({ payload, callback }) {
  try {
    const result = yield call(
      API.put,
      `/api/commerces/${payload.id}?groups[]=commerces`,
      payload
    );
    if (result.status === 201 || result.status === 200) {
      if (callback) {
        callback(result);
      }
      yield put({
        type: types.UPDATE_STORE_COMPLETE,
        payload: result.data,
      });
    }
  } catch (error) {
    yield put({ type: types.UPDATE_STORE_ERROR });
    yield put(
      openInfoModal({
        title: "No se pudo actualizar",
        info:
          "Disculpe, ocurrio un error al intentar actualizar la configuracion.",
      })
    );
  }
}

export function* getStoreManifestCall({ payload }) {
  try {
    const currentStore = yield select(userSelector);
    const result = yield call(
      API.get,
      `/api/commerces/${currentStore.commerce.id}/manifiest/orders?carrier=${payload}`
    );
    if (result.status >= 200 && result.status <= 299) {
      if (result.data && Array.isArray(result.data) && result.data.length) {
        yield put({
          type: types.GET_STORE_MANIFEST_COMPLETE,
          payload: result.data,
        });
      } else {
        yield put({ type: types.GET_STORE_MANIFEST_ERROR });
      }
    } else {
      yield put({ type: types.GET_STORE_MANIFEST_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_STORE_MANIFEST_ERROR });
  }
}

export function* postStoreManifestCall({ payload, callback }) {
  try {
    const currentStore = yield select(userSelector);
    const result = yield call(
      API.post,
      `/api/commerces/${currentStore.commerce.id}/manifiest?shippingsId=${payload}`
    );
    if (result.status >= 200 && result.status <= 299) {
      if (result.data && Array.isArray(result.data) && result.data.length) {
        result.data.forEach((item) => {
          window.open(item.summary, "_blank");
        });
        yield put({ type: types.POST_STORE_MANIFEST_COMPLETE });
        callback({ bool: true });
      } else {
        yield put({ type: types.POST_STORE_MANIFEST_ERROR });
      }
    } else {
      yield put({ type: types.POST_STORE_MANIFEST_ERROR });
    }
  } catch (error) {
    yield put({ type: types.POST_STORE_MANIFEST_ERROR });
  }
}

export function* getStoreManifestOrdersCall() {
  try {
    const currentStore = yield select(userSelector);
    const result = yield call(
      API.get,
      `/api/commerces/${currentStore.commerce.id}/manifiest/orders/quantity`
    );
    if (result.status >= 200 && result.status <= 299) {
      if (result.data && Object.keys(result.data).length) {
        let activeBtn = false;
        Object.values(result.data).forEach((item) => {
          if (item) {
            activeBtn = true;
          }
        });
        yield put({
          type: types.GET_STORE_MANIFEST_ORDERS_COMPLETE,
          payload: activeBtn,
          listManifiest: Object.keys(result.data),
        });
      } else {
        yield put({ type: types.GET_STORE_MANIFEST_ORDERS_ERROR });
      }
    } else {
      yield put({ type: types.GET_STORE_MANIFEST_ORDERS_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_STORE_MANIFEST_ORDERS_ERROR });
  }
}

export function* getProfileCommerceCall() {
  try {
    const currentStore = yield select(userSelector);
    const result = yield call(API.get, `/api/users/${currentStore.id}`);
    if (result.status >= 200 && result.status <= 299) {
      if (result.data && Object.keys(result.data).length) {
        yield put({
          type: types.GET_DETAIL_COMMERCE_COMPLETE,
          payload: result.data.commerce,
        });
      } else {
        yield put({ type: types.GET_DETAIL_COMMERCE_ERROR });
      }
    } else {
      yield put({ type: types.GET_DETAIL_COMMERCE_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_DETAIL_COMMERCE_ERROR });
  }
}

export function* getMatrixProducts({ page, itemsPerPage, query, id, idCommerce, fieldSort, callback }) {
  try {
    // const isAsc = payload.indexOf('ASC') !== -1;
    // const isDesc = payload.indexOf('DESC') !== -1;
    // const params = queryString.decode(payload);

    // const maxPrice = params.maxPrice ? parseInt(params.maxPrice, 10) : 1000000000;
    // const minPrice = params.minPrice ? parseInt(params.minPrice, 10) : 0;

    // const result = yield call(
    //   API.get,
    //   `/products?groups[]=product-1&commerce=${id}${page ? `&page=${page}` : ""}${itemsPerPage ? `&itemsPerPage=${itemsPerPage}` : ""}${query || ""}`,
    //   {
    //     headers: { noAuth: true },
    //   }
    // );
    const newResult = yield call( // ///////
      API.get,
      `/api/products-index-3?&groupByProduct=false&allowEmptyStock=1&joinCommerceStock=${idCommerce}&commerceId=${id}${fieldSort && fieldSort.field ? `&order[${fieldSort.field}]=${fieldSort.sort}` : "&order[cantidadBodega]=desc"}${page ? `&page=${page}` : ""}${itemsPerPage ? `&itemsPerPage=${itemsPerPage}` : ""}${query || ""}`,
    );
    if (newResult.status === 200) { // && Array.isArray(newResult.data.stocks)
      // const data = result.data.products.map((item) => ({
      //   ...item,
      //   variants: item.variants.map((v) => ({
      //     ...v,
      //     variants: Array.isArray(v.variants)
      //       ? v.variants
      //           .map((c) => ({
      //             ...c,
      //             stocks: Array.isArray(c.stocks)
      //               ? c.stocks
      //               : Object.keys(c.stocks).map((d) => c.stocks[d]),
      //           }))
      //           // .sort((a, b) => {
      //           //   const aValue = parseInt(a.price, 10);
      //           //   const bValue = parseInt(b.price, 10);
      //           //   if (aValue < minPrice || aValue > maxPrice) {
      //           //     return 1;
      //           //   }
      //           //   if (bValue < minPrice || bValue > maxPrice) {
      //           //     return -1;
      //           //   }
      //           //   if (bValue < aValue && isAsc) return 1;
      //           //   if (bValue > aValue && isAsc) return -1;
      //           //   if (bValue < aValue && isDesc) return -1;
      //           //   if (bValue > aValue && isDesc) return 1;
      //           //   return 0;
      //           // })
      //       : Object.keys(v.variants)
      //           .map((c) => ({
      //             ...v.variants[c],
      //             stocks: Array.isArray(v.variants[c].stocks)
      //               ? v.variants[c].stocks
      //               : Object.keys(v.variants[c].stocks).map((d) => v.variants[c].stocks[d]),
      //           }))
      //           // .sort((a, b) => {
      //           //   const aValue = parseInt(a.price, 10);
      //           //   const bValue = parseInt(b.price, 10);
      //           //   if (aValue < minPrice || aValue > maxPrice) {
      //           //     return 1;
      //           //   }
      //           //   if (bValue < minPrice || bValue > maxPrice) {
      //           //     return -1;
      //           //   }
      //           //   if (bValue < aValue && isAsc) return 1;
      //           //   if (bValue > aValue && isAsc) return -1;
      //           //   if (bValue < aValue && isDesc) return -1;
      //           //   if (bValue > aValue && isDesc) return 1;
      //           //   return 0;
      //           // }),
      //   })),
      // }));
      yield put({
        type: types.GET_MATRIX_PRODUCTS_COMPLETE,
        payload: {
          data: newResult.data.stocks, //  data,
          totalPages: Math.ceil(newResult.data.pagination.total / itemsPerPage), // Math.ceil(result.data.quantity / itemsPerPage),
        },
      });
      callback({ bool: true });
    } else {
      yield put({ type: types.GET_MATRIX_PRODUCTS_ERROR });
      callback({ bool: false });
    }
  } catch (error) {
    yield put({ type: types.GET_MATRIX_PRODUCTS_ERROR });
    callback({ bool: false });
  }
}


// export function* getMatrixProducts({ page, itemsPerPage, query, id }) {
//   try {
//     // const currentStore = yield select(userSelector);
//     // groups[]=product-1&
//     const result = yield call(API.get, 
//       `/products?groups[]=product-1&commerce=${id}${page ? `&page=${page}` : ""}${itemsPerPage ? `&itemsPerPage=${itemsPerPage}` : ""}${query || ""}`
//     );
//     if (result.status >= 200 && result.status <= 299) {
//       if (result.data && Object.keys(result.data).length) {
//         yield put({
//           type: types.GET_MATRIX_PRODUCTS_COMPLETE,
//           payload: result.data,                           // .commerce
//         });
//       } else {
//         yield put({ type: types.GET_MATRIX_PRODUCTS_ERROR });
//       }
//     } else {
//       yield put({ type: types.GET_MATRIX_PRODUCTS_ERROR });
//     }
//   } catch (error) {
//     yield put({ type: types.GET_MATRIX_PRODUCTS_ERROR });
//   }
// }

export function* getStocksByVariant({variantId,commerceId}) {
  try {
    // const currentStore = yield select(userSelector);
    // groups[]=product-1&
    const result = yield call(API.get, 
      `/api/stock?commerceId=${commerceId}&variantId=${variantId}`
    );
    if (result.status >= 200 && result.status <= 299) {
      if (result.data && Object.keys(result.data).length) {
        yield put({
          type: types.GET_STOCKS_BY_VARIANT_COMPLETE,
          payload: result.data,                           
        });
      } else {
        yield put({ type: types.GET_STOCKS_BY_VARIANT_ERROR });
      }
    } else {
      yield put({ type: types.GET_STOCKS_BY_VARIANT_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_STOCKS_BY_VARIANT_ERROR });
  }
}

export function* getTemporadas() {
  try {
    const result = yield call(API.get, `/temporadas`);
    if (result.status >= 200 && result.status <= 299) {
      if (result.data && Array.isArray(result.data)) { // && Object.keys(result.data).length
        yield put({
          type: types.GET_TEMPORADAS_COMPLETE,
          payload: result.data,
        });
      } else {
        yield put({ type: types.GET_TEMPORADAS_ERROR });
      }
    } else {
      yield put({ type: types.GET_TEMPORADAS_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_TEMPORADAS_ERROR });
  }
}

export function* getFiltersGral() {
  try {
    const result = yield call(API.get, `/value-filters-wharehouse`);
    if (result.status >= 200 && result.status <= 299) {
      if (result.data) { // && Object.keys(result.data).length
        yield put({
          type: types.GET_FILTERS_GRAL_COMPLETE,
          payload: result.data,
        });
      } else {
        yield put({ type: types.GET_FILTERS_GRAL_ERROR });
      }
    } else {
      yield put({ type: types.GET_FILTERS_GRAL_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_FILTERS_GRAL_ERROR });
  }
}

export default function* storeSaga() {
  yield takeLatest(types.GET_STORE_ORDERS_INIT, getStoreOrdersCall);
  yield takeLatest(types.UPDATE_STORE_MANAGER_INIT, updateStoreManagerCall);
  yield takeLatest(types.GET_STORE_SUBCATEGORY_INIT, getStoreSubCategory);
  yield takeLatest(types.GET_STORE_VARIANTS_INIT, getStoreVariantsCall);
  yield takeLatest(types.UPDATE_STORE_ORDER_INIT, updateStoreOrderCall);
  yield takeLatest(types.REFUND_ORDER_INIT, refundOrderCall);
  yield takeLatest(types.UPDATE_STORE_INIT, updateStoreCall);
  yield takeLatest(types.GET_STORE_MANIFEST_INIT, getStoreManifestCall);
  yield takeLatest(
    types.GET_STORE_MANIFEST_ORDERS_INIT,
    getStoreManifestOrdersCall
  );
  yield takeLatest(types.POST_STORE_MANIFEST_INIT, postStoreManifestCall);
  yield takeLatest(types.GET_DETAIL_COMMERCE, getProfileCommerceCall);
  yield takeLatest(types.GET_MATRIX_PRODUCTS_INIT, getMatrixProducts);
  yield takeLatest(types.GET_STOCKS_BY_VARIANT_INIT, getStocksByVariant);
  yield takeLatest(types.GET_TEMPORADAS_INIT, getTemporadas);
  yield takeLatest(types.GET_FILTERS_GRAL_INIT, getFiltersGral);
}
