import { REHYDRATE } from "redux-persist/es/constants";
import * as types from "./types";

const initialState = {
  localityStores: [],
  storeProducts: [],
  detailProduct: {},
  distancesAndIds: [],
};

export default function generalStore(state = initialState, action) {
  let incoming;
  switch (action.type) {
    case types.SET_CURRENT_STORE:
      return {
        ...state,
        currentStore: action.payload,
      };
    case types.GET_LOCALITY_STORES_INIT:
      return {
        ...state,
        isGettingLocalityStores: true,
      };
    case types.GET_LOCALITY_STORES_COMPLETE:
      return {
        ...state,
        isGettingLocalityStores: false,
        localityStores: action.payload,
        quantityStores: action.quantity
      };
    case types.GET_LOCALITY_STORES_ERROR:
      return {
        ...state,
        isGettingLocalityStores: false,
      };
    case types.GET_STORE_DETAILS_INIT:
      return {
        ...state,
        isGettingStoreDetails:
          !state.currentStore ||
          action.payload !== state.currentStore.id.toString(),
      };
    case types.GET_STORE_DETAILS_COMPLETE:
      return {
        ...state,
        isGettingStoreDetails: false,
        currentStore: action.payload,
      };
    case types.GET_STORE_DETAILS_ERROR:
      return {
        ...state,
        isGettingStoreDetails: false,
      };
    case types.GET_STORE_PRODUCTS_INIT:
      return {
        ...state,
        isGettingStoreProducts: true,
      };
    case types.GET_STORE_PRODUCTS_COMPLETE:
      return {
        ...state,
        isGettingStoreProducts: false,
        storeProducts: action.payload,
      };
    case types.GET_STORE_PRODUCTS_ERROR:
      return {
        ...state,
        isGettingStoreProducts: false,
      };

    case types.GET_CATEGORY_PRODUCTS_INIT:
      return {
        ...state,
        isGettingCategoryProducts: true,
      };
    case types.GET_CATEGORY_PRODUCTS_COMPLETE:
      return {
        ...state,
        isGettingCategoryProducts: false,
        categoryProducts: action.payload,
      };
    case types.GET_CATEGORY_PRODUCTS_ERROR:
      return {
        ...state,
        isGettingCategoryProducts: false,
      };
    case types.GET_PRODUCT_DETAIL_INIT:
      return {
        ...state,
        isGettingDetailProduct: true,
      };
    case types.GET_PRODUCT_DETAIL_COMPLETE:
      return {
        ...state,
        isGettingDetailProduct: false,
        detailProduct: action.payload,
      };
    case types.GET_PRODUCT_DETAIL_ERROR:
      return {
        ...state,
        isGettingDetailProduct: false,
      };
    case types.UPDATE_CART:
      return {
        ...state,
        cart: action.payload,
      };
    case types.UPDATE_CART_INIT:
      return {
        ...state,
        isUpdatingCart: true,
      };
    case types.UPDATE_CART_SUCCESS:
      return {
        ...state,
        cart: action.payload,
        isUpdatingCart: false,
      };
    case types.UPDATE_CART_ERROR:
      return {
        ...state,
        isUpdatingCart: false,
      };
    case types.GET_CART_INIT:
      return {
        ...state,
        isGettingCart: true,
      };
    case types.GET_CART_SUCCESS:
      return {
        ...state,
        cart: action.payload,
        isGettingCart: false,
      };
    case types.GET_CART_ERROR:
      return {
        ...state,
        isGettingCart: false,
      };
    case types.POST_REQUEST_CHECKOUT_INIT:
      return {
        ...state,
        isRequestingCheckout: true,
      };
    case types.POST_REQUEST_CHECKOUT_COMPLETE:
      return {
        ...state,
        isRequestingCheckout: false,
      };
    case types.POST_REQUEST_CHECKOUT_ERROR:
      return {
        ...state,
        isRequestingCheckout: false,
      };
    case types.REMOVE_ORDEN_CART_INIT:
      return {
        ...state,
        isUpdatingCart: true,
      };
    case types.REMOVE_ORDEN_CART_SUCCESS:
      return {
        ...state,
        isUpdatingCart: false,
      };
    case types.REMOVE_ORDEN_CART_ERROR:
      return {
        ...state,
        isUpdatingCart: false,
      };
    case types.REMOVE_ORNDE_INIT:
      return {
        ...state,
        isRemoveOrden: false,
        idOrnde: null,
      };
    case types.REMOVE_ORNDE_SUCCESS:
      return {
        ...state,
        isRemoveOrden: true,
        idOrden: action.payload,
      };
    case types.GET_ORNDEN_LIST_INIT:
      return {
        ...state,
        isLoadingOrdenList: true,
      };
    case types.GET_ORNDEN_LIST_COMPLETE:
      return {
        ...state,
        ordenList: action.payload,
        isLoadingOrdenList: false,
      };
    case types.GET_ORNDEN_LIST_ERROR:
      return {
        ...state,
        isLoadingOrdenList: false,
      };
    case types.GET_DETAIL_ORDEN_INIT:
      return {
        ...state,
        isLoadingDetailOrden: true,
      };
    case types.GET_DETAIL_ORDEN:
      return {
        ...state,
        detailOrden: action.detail,
        isLoadingDetailOrden: false,
      };
    case types.GET_DETAIL_ORDEN_ERROR:
      return {
        ...state,
        isLoadingDetailOrden: false,
      };
    case types.SELECT_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case types.GET_ORNDENS_DELIVERY_INIT:
      return {
        ...state,
        isLoadingDelivery: true,
        ordensDelivery: [],
      };
    case types.GET_ORNDENS_DELIVERY_COMPLETE:
      return {
        ...state,
        isLoadingDelivery: false,
        ordensDelivery: action.payload,
      };
    case types.GET_ORNDENS_DELIVERY_ERROR:
      return {
        ...state,
        isLoadingDelivery: false,
        ordensDelivery: [],
      };
    case types.GET_TRACKING_ORDEN_INIT:
      return {
        ...state,
        isLoadingTracking: true,
        trackingStatus: null,
      };
    case types.GET_TRACKING_ORDEN_COMPLETE:
      return {
        ...state,
        isLoadingTracking: false,
        trackingStatus: action.payload,
      };
    case types.GET_TRACKING_ORDEN_ERROR:
      return {
        ...state,
        isLoadingTracking: false,
        trackingStatus: null,
      };
      case types.SET_DISTANCES_AND_IDS_INIT:
      return {
        ...state,
        distancesAndIds: action.payload,
      };
    case REHYDRATE:
      incoming = action.payload ? action.payload.shoppingStore : null;
      if (incoming) {
        return {
          ...incoming,
          isUpdatingCart: false,
          isGettingCart: false,
          isRequestingCheckout: false,
        };
      }
      return state;
    default:
      return state;
  }
}
