export const localitiesSelector = (state) => state.generalStore.localities;
export const categoriesSelector = (state) => state.generalStore.categories;
export const infoModalSelector = (state) => state.generalStore.infoModal;
export const isOpenCartModalSelector = (state) =>
  state.generalStore.isOpenCartModal;
export const snackbarSelector = (state) => state.generalStore.snackbar;
export const selectedLocalitySelector = (state) =>
  state.generalStore.selectedLocality;
export const storeListSelector = (state) => state.generalStore.storeList;
export const unregisterFlagSelector = (state) =>
  state.generalStore.unregisterFlag;
export const idBrandLoginSelector = (state) => state.generalStore.idBrandLogin;