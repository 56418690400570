import React from "react";
import styled from "styled-components";
import AbsoluteLoader from "./AbsoluteLoader";

const Container = styled.div`
  height: 100vh;
`;

export default function SuspenseLoader() {
  return (
    <AbsoluteLoader visible>
      <Container />
    </AbsoluteLoader>
  );
}
