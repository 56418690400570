import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import { snackbarSelector } from "../ducks/general/selectors";
import { closeSnackbar } from "../ducks/general/actions";
import { primaryColor } from "../constants";

const Container = styled.div`
  padding: 12px 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 420px) {
  }
`;
const Message = styled.p`
  font-size: 12px;
  text-align: left;
  color: #f0f0f0;
  flex: 1;
`;

const Action = styled.p`
  text-transform: uppercase;
  color: ${primaryColor};
  font-size: 12px;
  margin-left: 20px;
`;

Modal.setAppElement("#root");

export default function Snackbar() {
  const snackbar = useSelector(snackbarSelector);
  const dispatch = useDispatch();
  const timeout = useRef(null);

  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  useEffect(() => {
    if (snackbar) {
      if (timeout && timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        dispatch(closeSnackbar());
      }, 3000);
    }
  }, [snackbar, dispatch]);

  return (
    <Modal
      isOpen={snackbar !== undefined && snackbar !== null && snackbar.open}
      onRequestClose={handleClose}
      // style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Snackbar"
      className="snackbar"
      overlayClassName="snackbar"
    >
      <Container onClick={snackbar && snackbar.onClickAction}>
        <Message>{snackbar && snackbar.message}</Message>
        {snackbar && snackbar.action ? (
          <Action>{snackbar.action}</Action>
        ) : null}
      </Container>
    </Modal>
  );
}
