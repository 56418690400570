import React, { useState } from 'react'
import ModalBase from '../modal/ModalBase/ModalBase'
import Button from '../Button'
import { dangerColor, whiteColor } from '../../constants'
import useConfirmationMessageModal from '../../hooks/useConfirmationMessageModal'
import SingleAbsoluteLoader from '../singleAbsoluteLoader/SingleAbsoluteLoader'
import styles from './styles.module.css'

const ConfirmationMessageModal = () => {
    const [isLoading, setIsLoading] = useState(false)
    const {
        confirmationMessageModal,
        isOpenConfirmationMessageModal,
        onAcceptConfirmationMessageModal,
        onCancelConfirmationMessageModal,
    } = useConfirmationMessageModal()
    
    const onAcceptAction = async () => {
        setIsLoading(true)
        await onAcceptConfirmationMessageModal()
        setIsLoading(false)
    }
    const onCloseAction = async () => {
        if (isLoading) {
            return
        }
        setIsLoading(true)
        await onCancelConfirmationMessageModal()
        setIsLoading(false)
    }
  return (
    <ModalBase 
        isOpen={isOpenConfirmationMessageModal} 
        onClose={onCloseAction}
        size='extra-small'
    >
        <>
            { isLoading && <SingleAbsoluteLoader className="rounded-3xl" /> }
            <div className={`min-h-[80px] mb-2 grid items-center justify-center ${styles.confirmationModalText}`}>{confirmationMessageModal}</div>
            <div className="flex justify-between">
                <Button width='90px' className='flex justify-center items-center' backgroundColor={dangerColor} color={whiteColor} onClick={onCloseAction}>
                    Cancelar
                </Button>
                <Button width='90px' className='flex justify-center items-center' color={whiteColor} onClick={onAcceptAction}>
                    Aceptar
                </Button>
            </div>
        </>
    </ModalBase>
  )
}

export default ConfirmationMessageModal
