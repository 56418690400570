export const REGISTER_INIT = "REGISTER_INIT";
export const REGISTER_COMPLETE = "REGISTER_COMPLETE";
export const REGISTER_ERROR = "REGISTER_ERROR";

export const LOGIN_INIT = "LOGIN_INIT";
export const LOGIN_COMPLETE = "LOGIN_COMPLETE";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const UPDATE_PROFILE_INIT = "UPDATE_PROFILE_INIT";
export const UPDATE_PROFILE_COMPLETE = "UPDATE_PROFILE_COMPLETE";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

export const SEND_SMS_INIT = "SEND_SMS_INIT";
export const SEND_SMS_COMPLETE = "SEND_SMS_COMPLETE";
export const SEND_SMS_ERROR = "SEND_SMS_ERROR";

export const VERIFY_PHONE_INIT = "VERIFY_PHONE_INIT";
export const VERIFY_PHONE_COMPLETE = "VERIFY_PHONE_COMPLETE";
export const VERIFY_PHONE_ERROR = "VERIFY_PHONE_ERROR";

export const GOOGLE_LOGIN_INIT = "GOOGLE_LOGIN_INIT";
export const GOOGLE_LOGIN_COMPLETE = "GOOGLE_LOGIN_COMPLETE";
export const GOOGLE_LOGIN_ERROR = "GOOGLE_LOGIN_ERROR";

export const FACEBOOK_LOGIN_INIT = "FACEBOOK_LOGIN_INIT";
export const FACEBOOK_LOGIN_COMPLETE = "FACEBOOK_LOGIN_COMPLETE";
export const FACEBOOK_LOGIN_ERROR = "FACEBOOK_LOGIN_ERROR";

export const LOGOUT = "LOGOUT";

export const CHANGE_PASSWORD_INIT = "CHANGE_PASSWORD_INIT";
export const CHANGE_PASSWORD_COMPLETE = "CHANGE_PASSWORD_COMPLETE";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const RECOVER_PASSWORD_INIT = "RECOVER_PASSWORD_INIT";
export const RECOVER_PASSWORD_COMPLETE = "RECOVER_PASSWORD_COMPLETE";
export const RECOVER_PASSWORD_ERROR = "RECOVER_PASSWORD_ERROR";

export const RESET_PASSWORD_INIT = "RESET_PASSWORD_INIT";
export const RESET_PASSWORD_COMPLETE = "RESET_PASSWORD_COMPLETE";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const CONFIRM_EMAIL_INIT = "CONFIRM_EMAIL_INIT";
export const CONFIRM_EMAIL_COMPLETE = "CONFIRM_EMAIL_COMPLETE";
export const CONFIRM_EMAIL_ERROR = "CONFIRM_EMAIL_ERROR";

export const CONTACT_INIT = "CONTACT_INIT";
export const CONTACT_INIT_COMPLETE = "CONTACT_INIT_COMPLETE";
export const CONTACT_INIT_ERROR = "CONTACT_INIT_ERROR";

export const IS_LOGIN_LOADING = "IS_LOGIN_LOADING";
export const IS_SOCIAL_LOGIN_LOADING = "IS_SOCIAL_LOGIN_LOADING";