import { put, call, takeLatest, select } from "redux-saga/effects";
import * as types from "./types";
// eslint-disable-next-line import/no-cycle
import API from "../../api";
import { userSelector } from "../access/selectors";

export function* getLocalitiesCall() {
  try {
    const result = yield call(
      API.get,
      "/api/countries?pagination=true&itemsPerPage=1",
      {
        headers: { noAuth: true },
      }
    );
    if (result.status === 200 && Array.isArray(result.data["hydra:member"])) {
      const localities = result.data["hydra:member"][0].regions;
      const newLocalities = [];
      localities.forEach((region) => {
        const regionCommunes = [];
        region.provinces.forEach((province) => {
          province.communes.forEach((commune) => {
            regionCommunes.push(commune);
          });
        });
        newLocalities.push({
          type: "group",
          ...region,
          provinces: null,
          items: regionCommunes.map(({ name, id }) => ({ name, value: id })),
        });
      });
      yield put({
        type: types.GET_LOCALITIES_COMPLETE,
        payload: newLocalities,
      });
    } else {
      yield put({ type: types.GET_LOCALITIES_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_LOCALITIES_ERROR });
  }
}

export function* getCategoriesCall({idBrand}) {
  try {
    const result = yield call(API.get, `/categories${idBrand ? `?brand=${idBrand}` : ""}`, {
      headers: { noAuth: true },
    });
    if (result.status === 200 && Array.isArray(result.data.categories)) {
      yield put({
        type: types.GET_CATEGORIES_COMPLETE,
        payload: result.data.categories,
      });
    } else {
      yield put({ type: types.GET_CATEGORIES_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_CATEGORIES_ERROR });
  }
}

export function* getStoreListCall() {
  try {
    const currentStore = yield select(userSelector);
    const result = yield call(API.get, `api/commerces?brand=${currentStore.brand.id}&pagination=false`);
    if (result.status === 200 && Array.isArray(result.data['hydra:member'])) {
      yield put({
        type: types.GET_STORE_LIST_COMPLETE,
        payload: result.data['hydra:member'],
      });
    } else {
      yield put({ type: types.GET_STORE_LIST_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GET_STORE_LIST_ERROR });
  }
}

export default function* general() {
  yield takeLatest(types.GET_LOCALITIES_INIT, getLocalitiesCall);
  yield takeLatest(types.GET_CATEGORIES_INIT, getCategoriesCall);
  yield takeLatest(types.GET_STORE_LIST_INIT, getStoreListCall);
}
