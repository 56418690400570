// import { REHYDRATE } from "redux-persist/es/constants";
import * as types from "./types";

const initialState = {
  localities: [],
  infoModal: null,
  manifestItems: [],
};

export default function storesStore(state = initialState, action) {
  // let incoming;
  switch (action.type) {
    case types.GET_STORE_ORDERS_INIT:
      return {
        ...state,
        isGettingStoreOrders: true,
      };
    case types.GET_STORE_ORDERS_COMPLETE:
      return {
        ...state,
        isGettingStoreOrders: false,
        storeOrders: action.payload,
      };
    case types.GET_STORE_ORDERS_ERROR:
      return {
        ...state,
        isGettingStoreOrders: false,
      };
    case types.SHOW_MANAGER_FORM:
      return {
        ...state,
        showManagerForm: action.payload,
      };
    case types.UPDATE_STORE_MANAGER_INIT:
      return {
        ...state,
        isUpdatingStoreManager: true,
      };
    case types.UPDATE_STORE_MANAGER_COMPLETE:
      return {
        ...state,
        isUpdatingStoreManager: false,
        showManagerForm: false,
      };
    case types.UPDATE_STORE_MANAGER_ERROR:
      return {
        ...state,
        isUpdatingStoreManager: false,
      };
    case types.UPDATE_STORE_INIT:
      return {
        ...state,
        isUpdatingStore: true,
      };
    case types.UPDATE_STORE_COMPLETE:
      return {
        ...state,
        isUpdatingStore: false,
      };
    case types.UPDATE_STORE_ERROR:
      return {
        ...state,
        isUpdatingStore: false,
      };
    case types.SET_CURRENT_STORE_ORDER:
      return {
        ...state,
        currentStoreOrder: action.payload,
      };
    case types.GET_STORE_VARIANTS_INIT:
      return {
        ...state,
        isGettingStoreVariants: true,
      };
    case types.GET_STORE_VARIANTS_COMPLETE:
      return {
        ...state,
        isGettingStoreVariants: false,
        storeVariants: action.payload,
      };
    case types.GET_STORE_VARIANTS_ERROR:
      return {
        ...state,
        isGettingStoreVariants: false,
      };
      case types.GET_STORE_SUBCATEGORY_INIT:
        return {
          ...state,
          isLoadingStoreSubCategory: true,
          storeSubCategory: null,
        };
      case types.GET_STORE_SUBCATEGORY_COMPLETE:
        return {
          ...state,
          isLoadingStoreSubCategory: false,
          storeSubCategory: action.payload,
        };
      case types.GET_STORE_SUBCATEGORY_ERROR:
        return {
          ...state,
          isLoadingStoreSubCategory: false,
        };
    case types.UPDATE_STORE_ORDER_INIT:
      return {
        ...state,
        isUpdatingStoreOrder: true,
      };
    case types.UPDATE_STORE_ORDER_COMPLETE:
      return {
        ...state,
        isUpdatingStoreOrder: false,
        currentStoreOrder: {
          ...state.currentStoreOrder,
          status: action.payload.status,
          ticket: action.payload.ticket
        },
      };
    case types.UPDATE_STORE_ORDER_ERROR:
      return {
        ...state,
        isUpdatingStoreOrder: false,
      };
    case types.REFUND_ORDER_INIT:
      return {
        ...state,
        isUpdatingStoreOrder: true,
      };
    case types.REFUND_ORDER_COMPLETE:
      return {
        ...state,
        isUpdatingStoreOrder: false,
        currentStoreOrder: action.payload,
      };
    case types.REFUND_ORDER_ERROR:
      return {
        ...state,
        isUpdatingStoreOrder: false,
      };
    case types.GET_STORE_MANIFEST_INIT:
      return {
        ...state,
        isLoadingManifest: true,
        manifest: [],
      };
    case types.GET_STORE_MANIFEST_COMPLETE:
      return {
        ...state,
        isLoadingManifest: false,
        manifest: action.payload,
      };
    case types.GET_STORE_MANIFEST_ERROR:
      return {
        ...state,
        isLoadingManifest: false,
        manifest: [],
      };
    case types.UPDATE_STORE_MANIFEST_INIT:
      return {
        ...state,
        manifestItems: {},
      };
    case types.UPDATE_STORE_MANIFEST_COMPLETE:
      return {
        ...state,
        manifestItems: action.payload,
      };
    case types.POST_STORE_MANIFEST_INIT:
      return {
        ...state,
        isLoadingManifestCreate: true,
      };
    case types.POST_STORE_MANIFEST_COMPLETE:
      return {
        ...state,
        isLoadingManifestCreate: false,
      };
    case types.POST_STORE_MANIFEST_ERROR:
      return {
        ...state,
        isLoadingManifestCreate: false,
      };
    case types.GET_STORE_MANIFEST_ORDERS_INIT:
      return {
        ...state,
        isLoadingManifestOrders: true,
        activeBtn: false,
      };
    case types.GET_STORE_MANIFEST_ORDERS_COMPLETE:
      return {
        ...state,
        isLoadingManifestOrders: false,
        activeBtn: action.payload,
        listManifiest: action.listManifiest,
      };
    case types.GET_STORE_MANIFEST_ORDERS_ERROR:
      return {
        ...state,
        isLoadingManifestOrders: false,
        activeBtn: false,
      };
    case types.GET_DETAIL_COMMERCE_COMPLETE:
      return {
        ...state,
        detailStore: action.payload,
      };
      case types.GET_MATRIX_PRODUCTS_INIT:
        return {
          ...state,
          isLoadingMatrixProducts: true,
          matrixProducts: null,
          errorMatrixProducts:null,
        };
      case types.GET_MATRIX_PRODUCTS_COMPLETE:
        return {
          ...state,
          isLoadingMatrixProducts: false,
          // matrixProducts: action.payload,
          matrixProducts: action.payload.data,
          totalPagesProducts: action.payload.totalPages,
          errorMatrixProducts:null,
        };
      case types.GET_MATRIX_PRODUCTS_ERROR:
        return {
          ...state,
          isLoadingMatrixProducts: false,
          matrixProducts: action.payload,
          errorMatrixProducts:"error.GET_MATRIX_PRODUCTS_ERROR",
        };
        case types.GET_STOCKS_BY_VARIANT_INIT:
            return {
              ...state,
              loadStocksByVariant: true,
              stocksByVariant: null,
              errorStocksByVariant: null,
            };
          case types.GET_STOCKS_BY_VARIANT_COMPLETE:
            return {
              ...state,
              loadStocksByVariant: false,
              stocksByVariant: action.payload,
              errorStocksByVariant: null,
            };
          case types.GET_STOCKS_BY_VARIANT_ERROR:
            return {
              ...state,
              loadStocksByVariant: false,
              stocksByVariant: null,
              errorStocksByVariant: "error.GET_STOCKS_BY_VARIANT_ERROR",
            };
            case types.UPDATE_I_VARIANT_2_INIT:
              return {
                ...state,
                iVariant1Gral: action.iVariant1Gral,
                iVariant2Gral: action.iVariant2Gral,
            };
            case types.GET_TEMPORADAS_INIT:
              return {
                ...state,
                loadTemporadas: true,
                temporadas: null,
                errorTemporadas: null,
              };
            case types.GET_TEMPORADAS_COMPLETE:
              return {
                ...state,
                loadTemporadas: false,
                temporadas: action.payload,
                errorTemporadas: null,
              };
            case types.GET_TEMPORADAS_ERROR:
              return {
                ...state,
                loadTemporadas: false,
                temporadas: null,
                errorTemporadas: "error.GET_TEMPORADAS_ERROR",
              };
            case types.GET_FILTERS_GRAL_INIT:
                return {
                  ...state,
                  loadFiltersGral: true,
                  filtersGral: null,
                  errorFiltersGral: null,
                };
            case types.GET_FILTERS_GRAL_COMPLETE:
                return {
                  ...state,
                  loadFiltersGral: false,
                  filtersGral: action.payload,
                  errorFiltersGral: null,
                };
            case types.GET_FILTERS_GRAL_ERROR:
                return {
                  ...state,
                  loadFiltersGral: false,
                  filtersGral: null,
                  errorFiltersGral: "error.GET_FILTERS_GRAL_ERROR",
                };
            case types.SET_FIELD_SORT:
                return {
                    ...state,
                    fieldSort: action.fieldSort,
                };
   
    default:
      return state;
  }
}
