import React from 'react';
import Modal from 'react-modal';
import { MdCancel } from "react-icons/md";
import classNames from 'classnames';
import styles from './styles.module.css';

Modal.setAppElement("#root");

function ModalBase({
    isOpen,
    onClose,
    children,
    size = "small",
    overlayClassName = "",
    modalClassName = "",
    contentClassName = "",
    canClose = true,
    limitHeight = true
}){
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={`${styles.modal} ${styles[size]} ${modalClassName}`}
            overlayClassName={`${styles.overlay} ${overlayClassName}`}
            closeTimeoutMS={300}
        >  
            <>
                {canClose && <MdCancel onClick={onClose} size={29} color="#b1bbcd" className="opacity modal-button-close" />}
                <div className={classNames(
                    styles.container, 
                    contentClassName,
                    { [styles.limitHeight]: limitHeight }
                )}>
                    {children}
                </div>
            </>
        </Modal>
    );
}

export default ModalBase;