import React from "react";
import { MdCancel } from "react-icons/md";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import Button from "./Button";
import { infoModalSelector } from "../ducks/general/selectors";
import { closeInfoModal } from "../ducks/general/actions";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 0,
    marginRight: "-50%",
    borderRadius: 20,
    boxShadow: "0 20px 20px 0 rgba(0, 0, 0, 0.1)",
    border: "none",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
    zIndex: 999999999999999999999999999999
  },
};

const Container = styled.div`
  padding: 16px;
  padding-bottom: 20px;
  position: relative;
  width: calc(100vw - 20px);
  max-width: 280px;
  min-height: 280px;
  display: flex;
  zIndex: 999999999999999999999999999999;
  flex-direction: column;
  @media (max-width: 420px) {
    padding: 10px;
    padding-bottom: 20px;
  }
`;

const Content = styled.div`
  flex: 1;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #fff;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.p`
  font-size: 18px;
  text-align: center;
  margin: 40px 0px;
  font-weight: bold;
  color: #404040;
`;
const Info = styled.p`
  font-size: 14px;
  text-align: center;
  color: #808080;
  font-weight: 500;
`;

Modal.setAppElement("#root");

export default function InfoModal() {
  const infoModal = useSelector(infoModalSelector);
  const dispatch = useDispatch();

  const handleClose = () => {
    if (infoModal && typeof infoModal.onClose === "function"){
      infoModal.onClose();
    }
    dispatch(closeInfoModal());
  };

  const handleAccept = () => {
    if (infoModal && typeof infoModal.onAccept === "function") {
      infoModal.onAccept();
    }
    dispatch(closeInfoModal());
  };

  return (
    <Modal
      isOpen={infoModal && infoModal.isOpen}
      onRequestClose={handleClose}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Detalle del producto"
    >
      <Container>
        <CloseButton onClick={handleClose}>
          <MdCancel className="opacity" size={29} color="#b1bbcd" />
        </CloseButton>
        <Content>
          <Title>{infoModal && infoModal.title}</Title>

          <Info>{infoModal && infoModal.info}</Info>
        </Content>
        <div className="total-center">
          <Button onClick={handleAccept} width="200px" margin="20px">
            Aceptar
          </Button>
        </div>
      </Container>
    </Modal>
  );
}
